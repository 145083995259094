
import { Component, Vue } from "vue-property-decorator";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import PropertyPageLayout from "@/components/layout/PropertyPageLayout.vue";
import DocumentsMainDocuments from "@/components/DocumentsMainDocuments.vue";
import DocumentsMainDocumentsShimmer from "@/components/DocumentsMainDocumentsShimmer.vue";
import DocumentsPastLeasings from "@/components/DocumentsPastLeasings.vue";
import DocumentsPastLeasingsShimmer from "@/components/DocumentsPastLeasingsShimmer.vue";
import ContactPerson from "@/components/ContactPerson.vue";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import { HousfyDocumentCard } from "housfy-ui-lib";
import { IBackOptions } from "@/models/BackOptions";
import { IAddress } from "@/models/Address";
import { IPropertyInfo } from "@/models/PropertyInfo";
import {
  IRentalLeasings,
  IRentalLeasing,
  IRentalLeasingFormatted,
} from "@/models/RentalLeasing";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { resolveRoutePath } from "@/router";
import { TabbarIds } from "@/enums/HousfyPage";
import DateUtils from "@/services/utils/DateUtils";
import api from "@/api";
import ErrorService from "@/services/ErrorService";
import ContactService from "@/services/ContactService";
import LeasingsService from "@/services/LeasingsService";

@Component({
  name: "Documents",
  components: {
    HousfyPage,
    PropertyPageLayout,
    DocumentsMainDocuments,
    DocumentsMainDocumentsShimmer,
    DocumentsPastLeasings,
    DocumentsPastLeasingsShimmer,
    ContactPerson,
    HousfyLink,
    HousfyDocumentCard,
  },
})
export default class Documents extends Vue {
  address: IAddress | null = null;
  propertyInfo: IPropertyInfo | null = null;
  leasings: IRentalLeasings | null = null;
  errorMessage: string | null = null;

  activeTabId = TabbarIds.ACTIVITY;
  loading = false;
  propertyUuid: string = this.$route.params.propertyUuid;

  async mounted() {
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;

    try {
      [this.address, this.propertyInfo, this.leasings] = await Promise.all([
        api.property().retrieveAddress(this.propertyUuid),
        api.property().retrieveInfo(this.propertyUuid),
        api.property().retrieveLeasings(this.propertyUuid),
      ]);
    } catch (error) {
      this.errorMessage = ErrorService.getErrorFromCode(error);
    }

    this.loading = false;
  }

  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      preventDefault: false,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
    };
  }
  get headerTitle() {
    return this.address?.fullAddress || "";
  }
  get currentLeasing(): IRentalLeasingFormatted | undefined {
    const currentLeasing = LeasingsService.getCurrentLeasing(
      this.leasings?.items
    );
    let currentLeasingFormatted;
    if (currentLeasing) {
      currentLeasingFormatted = {
        ...currentLeasing,
        periodDate: this.leasingFormatDate(currentLeasing),
      };
    }
    return currentLeasingFormatted;
  }
  get pastLeasings(): IRentalLeasingFormatted[] {
    const pastLeasings = LeasingsService.getPastLeasings(this.leasings?.items);
    const pastLeasingsSorted = LeasingsService.sortLeasings(pastLeasings);
    return pastLeasingsSorted.map((leasing) => ({
      ...leasing,
      periodDate: this.leasingFormatDate(leasing),
    }));
  }
  get showContactPerson(): boolean {
    return ContactService.shouldShowContactPerson(
      this.propertyInfo,
      this.currentLeasing
    );
  }

  leasingFormatDate(leasing: IRentalLeasing | undefined): string {
    if (!leasing) return "";
    const { leasingContractValidityDate, leasingTerminationDate } = leasing;
    const startDate = leasingContractValidityDate
      ? this.formatDate(leasingContractValidityDate)
      : "";
    const endDate = leasingTerminationDate
      ? this.formatDate(leasingTerminationDate)
      : this.$t("common.present");
    return `${startDate} - ${endDate}`;
  }
  formatDate(stringDate: string): string {
    const { day, month, year } = DateUtils.formatDate(stringDate);
    return day + " " + this.$t("common." + month + "Short") + " " + year;
  }
}
